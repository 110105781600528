import React from "react"
import classes from "./Dropdown.module.css"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import HomeImage from "../gatsbyImages/HomeImage.js"
import HomeImageInverted from "../gatsbyImages/HomeImageInverted.js"

function Dropdown({
  questionnaire = false,
  white = false,
  white2 = false,
  white3 = false,
  home = false,
}) {
  return (
    <div
      className={
        white
          ? classes.dropdownContainerWhite
          : white2
          ? classes.dropdownContainerWhite2
          : white3
          ? classes.dropdownContainerWhite3
          : questionnaire
          ? classes.questionnaire
          : home
          ? classes.dropdownContainerHome
          : classes.dropdownContainer
      }
    >
      <Link to="/">
        <div className="w-8 mb-2">
          {white || white2 || white3 ? <HomeImageInverted /> : <HomeImage />}
        </div>
      </Link>
      <Link to="/video-editing">
        <button className="font-bold text-lg outline-none border-none leading-tight">
          why us
        </button>
      </Link>
      <Link to="/portfolio">
        <button className="font-bold text-lg outline-none border-none leading-tight">
          portfolio
        </button>
      </Link>
      <Link to="/about">
        <button className="font-bold text-lg outline-none border-none leading-tight">
          team
        </button>
      </Link>
      <Link to="/categorization">
        <button className="font-bold text-lg outline-none border-none leading-tight">
          pricing
        </button>
      </Link>
      <Link to="#">
        <button className="font-bold text-lg outline-none border-none leading-tight">
          school
        </button>
      </Link>
      <AnchorLink to="/video-editing#contactus" stripHash>
        <button className="font-bold text-lg outline-none border-none leading-tight">
          contact
        </button>
      </AnchorLink>
    </div>
  )
}

export default Dropdown
