import React, { useState } from "react"
import { Link } from "gatsby"
import Dropdown from "../components/Dropdown"
import Root from "../components/root"
import LogoImage from "../gatsbyImages/LogoImage"
import classes from "./index.module.css"
import Layout from "../components/layout"

const IndexPage = () => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  return (
    <Layout>
      {isDropdownOpen && <Dropdown home={true} />}
      {!isDropdownOpen && (
        <div
          className={classes.logo}
          onClick={e => setisDropdownOpen(!isDropdownOpen)}
        >
          <LogoImage />
        </div>
      )}

      <Root
        className="text-white relative z-10 w-full h-screen"
        onClick={e => setisDropdownOpen(false)}
      >
        <div className="max-w-xs xl:max-w-6xl relative  mx-auto">
          <Link to="/video-editing" className={classes.aboutThisGig}>
            <span className="font-thin uppercase">about this</span>
            <span className="font-black leading-8 uppercase">
              video editing
            </span>
            <span className="font-thin uppercase">gig</span>
          </Link>

          <Link to="/about" className={classes.meetTheTeam}>
            <span className="font-thin uppercase">meet the</span>
            <span className="font-black leading-8 uppercase">team</span>
          </Link>

          <Link to="/categorization" className={classes.ourPricing}>
            <span className="font-thin uppercase">our</span>
            <span className="font-black leading-8 uppercase">pricing</span>
          </Link>

          <Link to="#" className={classes.editingSchool}>
            <span className="font-thin uppercase ml-2 xl:ml-0">the</span>
            <span className="font-black xl:leading-8 uppercase ml-2 xl:ml-0">
              editing <br className="xl:hidden" /> school
            </span>
            <span className="font-thin uppercase ml-2 xl:ml-0">
              coming soon
            </span>
          </Link>
        </div>
      </Root>
    </Layout>
  )
}

export default IndexPage
